import * as React from "react"

const faqs = [
  {
    question: "What is it?",
    answer:
      "An additive manufacturing platform which allows multiple printheads to deposit material on multiple beds in a continuous and uninterrupted way using rotation as its main drive mechanism.",
  },
  {
    question: "Who is this for?",
    answer:
      "Additive manufacturing OEMs, Client Service providers, and high throughput 3D printing applications.",
  },
  {
    question: "What are the main advantages?",
    answer:
      "The rotative aspect completely eliminates unecessary acceleration, making the printing process more controlled and more efficient through a constant RPM. With this concept, a single machine can print on multiple beds using multiple heads. Since the printheads can access all the beds at any given moment, the printing process is exponentially more efficient compared to the standard single printhead printing on a single machine. Each additional bed and printhead makes it exponentially faster and more efficient, all with the same machine space. The concept also allows for indepent access of finish part(s) on a bed, while the printheads continue printing on the remainder of the parts on the beds, allowing for uninterupted printing.",
  },
]

const Faq = () => {
  return (
    <div className="bg-white mt-6">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-primary">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              Can’t find the answer you’re looking for? Reach out to me here{" "}
              <a
                href="mailto:info@toremlabs.com"
                target="_blank"
                rel="noopener noreferrer"
                className="font-medium text-primary hover:text-secondary"
              >
                info@toremlabs.com
              </a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              {faqs.map(faq => (
                <div key={faq.question}>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-justify text-base text-gray-500">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
