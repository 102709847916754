import * as React from "react"
import Lottie from "react-lottie"

import animationData from "../images/animation.json"

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <main className="lg:relative z-0">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-left z-0">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">We revolutionize</span>{" "}
            <span className="block text-secondary xl:inline">
              additive manufacturing
            </span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Contact us today to discuss how to integrate this concept to your
            particular application.
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="mt-3 rounded-md shadow sm:mt-0 w-full h-full md:w-1/2">
              <a
                href="https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/11548223"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full truncate flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md bg-secondary hover:bg-primary text-white md:py-4 md:text-lg md:px-10"
              >
                Read the Patent
              </a>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 h-full w-full md:w-1/2">
              <a
                href="https://youtu.be/_eQCphmVt0w"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full truncate flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md bg-secondary hover:bg-primary text-white md:py-4 md:text-lg md:px-10"
              >
                Watch Presentation
              </a>
            </div>
          </div>
          <div className="mt-3 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow w-full">
              <a
                href={`mailto:info@toremlabs.com`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-secondary md:py-4 md:text-lg md:px-10"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <div className="hidden lg:block">
          <Lottie options={defaultOptions} height={700} width={700} />
        </div>
        <div className="lg:hidden">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      </div>
    </main>
  )
}

export default Hero
