import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Faq from "../components/faq"
import Testimonial from "../components/testimonial"

const index = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <Faq />
      <Testimonial />
    </Layout>
  )
}

export default index
